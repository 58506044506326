div#lhnHocButton.lhntab {
	top: auto;
	bottom: 16px;
}
div#lhnHelpOutCenter.lhnWindow.lhnActive {
	max-height: 420px;
}
#lhnHelpOutCenter div.lhnHocSection {
	height: calc(100% - 13px);
}

div#lhnHocButton div.lhnHocChatBtnCont {
	background-color: #212121;
}

div#lhnHocButton.lhnround div.lhnHocChatBtnCont {
	background-color: transparent;
}

div#lhnHocButton div.lhnHocChatBtn {
	background-color: #212121;
}

div#lhnHocButton div.lhnHocChatBtn_border {
	border: 4px solid #212121;
}

div#lhnHocButton div.lhnHocChatBtnShadow {
	box-shadow: -2px 3px 8px 1px #a0a0a0;
}

div#lhnHocButton div.lhnHocFrontBubble {
	background-color: #ffffff;
}

div#lhnHocButton div.lhnHocFrontBubble:before {
	border-color: #ffffff transparent transparent #ffffff;
}

div#lhnHocButton.lhnround div.lhnHocFrontBubble:after {
	border-color: transparent #ffffff #ffffff transparent;
}

div#lhnHocButton div.lhnHocChatTalk {
	background-color: #212121;
}

#lhnHelpOutCenter div.lhnRadioGroup input:checked + span {
	background-color: #e0e0e0;
	color: white;
}

div#lhnHocButton div.lhnHocBackBubble {
	background-color: #e0e0e0;
}

div#lhnHocButton div.lhnHocBackBubble:after {
	border-color: #e0e0e0 #e0e0e0 transparent transparent;
}

div#lhnHocInvite div.lhnHocInviteCont {
	background-color: #212121;
	color: #ffffff;
}

div#lhnHocInvite div.lhnHocInviteButton {
	border: 1px solid #ffffff;
	background-color: #f0f0f0;
	color: #999;
}

div#lhnHocInvite div.lhnHocInviteButton.lhnstart {
	background-color: #ffffff;
	color: #999;
}

#lhnHelpOutCenter button.lhnFormButton,
#lhnHelpOutCenter button.form_submit {
	background-color: #212121;
	color: white;
}

#lhnHelpOutCenter button.lhnFormButton:hover,
#lhnHelpOutCenter button.form_submit:hover {
	background-color: #666666;
}

#lhnHelpOutCenter div.lhnWindow-header {
	color: white;
	background-color: #212121;
}

#lhnHelpOutCenter div.lhnCustomerMessage div.lhnWindow-message {
	background-color: #212121;
	color: white;
}

#lhnHelpOutCenter div.lhnCustomerMessage div.lhnWindow-message:after {
	border-color: transparent transparent transparent #212121;
}

#lhnHelpOutCenter div.lhnOperatorMessage div.lhnWindow-message a {
	color: #212121;
}

#lhnHelpOutCenter div.lhnWindow-chat_input button {
	background-color: #212121;
	color: white;
}

#lhnHelpOutCenter div.lhnWindow-chat_input button:hover {
	background-color: #666666;
}

#lhnHelpOutCenter div.lhnPrompt button.lhnFormButton {
	border: 2px solid #212121;
}

#lhnHelpOutCenter div.lhnPrompt button.lhnFormButton.lhnPromptDecline {
	background-color: #ffffff;
	color: #212121;
}

#lhnHelpOutCenter div.lhnChatActionsMenu img {
	background-color: #212121;
}

/* Jump Logic */

#lhnHelpOutCenter div.jumplogic .lhnOptions .lhnOption {
	color: #212121;
}

#lhnHelpOutCenter div.jumplogic .lhnOptions .lhnOption:hover {
	background-color: #212121;
	color: #ffffff;
}
